export const baseViewFormConfig = {
  labelWidth: '140px',
  itemStyle: {
    padding: '0px 20px'
  },
  formItems: [
    {
      field: 'createdBy',
      type: 'input',
      label: 'profile.sender',
      colLayout: {
        xl: 12,
        lg: 12,
        md: 12,
        sm: 12,
        xs: 12
      },
      otherOptions: {
        disabled: true
      }
    },
    {
      field: 'userNames',
      type: 'input',
      label: 'profile.receiver',
      colLayout: {
        xl: 12,
        lg: 12,
        md: 12,
        sm: 12,
        xs: 12
      },
      otherOptions: {
        disabled: true
      }
    },
    {
      field: 'title',
      type: 'input',
      label: 'profile.title',
      colLayout: {
        xl: 24,
        lg: 24,
        md: 24,
        sm: 24,
        xs: 24
      },
      otherOptions: {
        disabled: true
      }
    },
    {
      field: 'content',
      type: 'editor',
      label: 'profile.message',
      colLayout: {
        xl: 24,
        lg: 24,
        md: 24,
        sm: 24,
        xs: 24
      },
      otherOptions: {
        disabled: true,
        isShowToolBar: false
      }
    }
  ]
}
