import { getGlobalParamsOptionsAsync } from '@/utils/common.js'

export const baseNewFormConfig = {
  labelWidth: '140px',
  itemStyle: {
    padding: '0px 20px'
  },
  formItems: [
    {
      field: 'userIds',
      type: 'select',
      label: 'profile.receiver',
      options: [],
      isResolveGlobalParams: true,
      handler: () =>
        getGlobalParamsOptionsAsync('userList', {
          isNeedPreFix: false,
          valueParams: 'id',
          labelParams2: 'userName'
        }),
      otherOptions: {
        multiple: true
      },
      colLayout: {
        xl: 12,
        lg: 12,
        md: 12,
        sm: 12,
        xs: 12
      }
    },
    {
      field: 'title',
      type: 'input',
      label: 'profile.title',
      colLayout: {
        xl: 24,
        lg: 24,
        md: 24,
        sm: 24,
        xs: 24
      },
      otherOptions: {
        maxlength: 100
      }
    },
    {
      field: 'content',
      type: 'editor',
      label: 'profile.message',
      colLayout: {
        xl: 24,
        lg: 24,
        md: 24,
        sm: 24,
        xs: 24
      },
      otherOptions: {
        placeholder: 'profile.message-v'
      }
    }
  ],
  validateRules: {
    userIds: [
      {
        required: true,
        message: 'general.required',
        trigger: 'change'
      }
    ],
    title: [
      {
        required: true,
        message: 'general.required',
        trigger: 'change'
      }
    ],
    content: [
      {
        required: true,
        message: 'general.required',
        trigger: 'change'
      }
    ]
  }
}
