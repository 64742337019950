<template>
  <div class="base-form">
    <page-form
      ref="baseFormRef"
      v-bind="configOptions"
      :pageInfo="pageInfo"
      @handleNewClick="handleSubmit"
    >
    </page-form>
  </div>
</template>

<script setup>
import pageForm from '@/components/PageForm/page-form.vue'
import { ref, computed, getCurrentInstance } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { baseNewFormConfig } from './config/new.js'
import { baseViewFormConfig } from './config/view.js'

import i18n from '@/i18n'
import { ElMessage } from 'element-plus'
import { cloneDeep } from 'lodash'

const cxt = getCurrentInstance()

const handleMessageCommit =
  cxt.appContext.config.globalProperties.$handleMessageCommit

const routes = useRoute()
const router = useRouter()
const store = useStore()
const { t } = i18n.global

// const prefixTitle = routes.meta.prefixTitle
//   ? t(routes.meta.prefixTitle) + ' '
//   : ''
// const title = prefixTitle + t(routes.meta.title)

// 1、预定义当前页面的map映射参数
const pageMapArray = [
  {
    // view page
    routesName: 'viewMessage',
    baseFormConfig: baseViewFormConfig,
    pageTitle: 'View Message',
    pageType: 'view'
  },
  {
    // add page
    routesName: 'newMessage',
    baseFormConfig: baseNewFormConfig,
    pageTitle: 'Add Message',
    pageType: 'new',
    isResetFields: false
  }
]

// 2、根据当前的路由对象中的name属性判断当前页面
const currentPageConfig = pageMapArray.find((item) => {
  if (item.routesName === routes.name) return item
})

// 3、将需要用到的属性解构出来
const { baseFormConfig, pageType } = currentPageConfig

const baseFormConfigRef = computed(() => {
  return baseFormConfig
})

// 4、区分当前页面是否需要转化options选项
const configOptions = ref({})
currentPageConfig.baseFormConfig = baseFormConfigRef
configOptions.value = currentPageConfig

const id = routes.params.id
// 5、获取当前页面的初始化数据

const pageInfo = ref({})

const getCurrentPageInfo = async () => {
  const messageInfo = await store.dispatch('system/getSendMessageById', id)
  pageInfo.value = messageInfo
}

// 获取当前页面的信息 view page 和 edit page 都需要回显信息
if (pageType === 'view') {
  getCurrentPageInfo()
}

// 提交表单
const handleSubmit = async (data) => {
  const form = cloneDeep(data)
  if (data.content === '<p><br></p>') {
    return ElMessage.warning(t('popup.message-empty'))
  }
  form.userIds = form.userIds.join(',')
  handleMessageCommit('system/handleSendMessageSubmit', form).then(() =>
    router.go(-1)
  )
}
</script>
